<template>
  <div>
    <!-- 시나리오 목록 -->
    <c-table
      ref="table"
      title="LBL0001165"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in $comm.ibmTagItems(props.row)"
            :key="index"
            outline square
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="linkClick(item, props.row)">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template>
        <template v-else>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'charm-scenario-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
        ],
        columns: [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'materialName',
            field: 'materialName',
            // 화학자재
            label: 'LBL0000356',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            fix: true,
            name: 'existingRiskManagementActivities',
            field: 'existingRiskManagementActivities',
            // 현재안전보건조치
            label: 'LBL0001148',
            align: 'left',
            style: 'width:270px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: 'LBL0001008',
            align: 'center',
            child: [
              {
                name: 'beforeExposureLevelFinal',
                field: 'beforeExposureLevelFinal',
                // 노출수준
                label: 'LBL0001142',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'beforeToxic',
                field: 'beforeToxic',
                // 유해성
                label: 'LBL0001127',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'beforeRiskEstimation',
                field: 'beforeRiskEstimation',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:60px',
                sortable: false,
              },
            ]
          },
          {
            name: 'addingRiskManagementActivies',
            field: 'addingRiskManagementActivies',
            // 감소대책
            label: 'LBL0001149',
            align: 'left',
            style: 'width:270px',
            sortable: false,
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterExposureLevelFinal',
                field: 'afterExposureLevelFinal',
                // 노출수준
                label: 'LBL0001142',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'afterToxic',
                field: 'afterToxic',
                // 유해성
                label: 'LBL0001127',
                align: 'center',
                style: 'width:60px',
                sortable: false
              },
              {
                name: 'afterRiskEstimation',
                field: 'afterRiskEstimation',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:60px',
                type: 'proxy',
                sortable: false,
              },
            ]
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            userId: 'assessUserId',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: 'auto' //'600px'
      },
      requestImprRow: null,
      imprData: null,
      colorItems: [],
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.charm.scenario.result.url
      // code setting
      // this.$comm.getStepItems('IBM_STEP_CD').then(_result => {
      //   this.colorItems = _result
      // });
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
          this.requestImprRow.riskRegisterFlag = true
        }
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      }
    },
  }
};
</script>
